import React from "react"
import { Seo, Layout } from "../components"
import { StaticImage } from "gatsby-plugin-image"
import { BadgeCheckIcon, PresentationChartLineIcon, CurrencyEuroIcon, BriefcaseIcon, CogIcon, ChevronDownIcon } from '@heroicons/react/solid'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Disclosure, Transition } from '@headlessui/react'


const ConceptionSiteInternet = () => {

  const features = [
    {
      name: 'Rassurer vos clients potentiels',
      description: 'Gagnez en crédibilité ! 56% des internautes n\'ont pas confiance en une entreprise sans site Internet.',
      icon: BadgeCheckIcon,
    },
    {
      name: 'Renforcer votre visibilité en ligne',
      description: 'Faites connaître votre activité et générez plus de prospects. Votre site Internet est le moyen le plus efficace de vous faire connaître en ligne.',
      icon: PresentationChartLineIcon,
    },
    {
      name: 'Présenter votre entreprise, vos produits & services',
      description: 'Positionnez vous comme un expert dans votre secteur d\'activité en présentant votre activité et vos prestations.',
      icon: BriefcaseIcon,
    },
    {
      name: 'Augmenter votre chiffre d\'affaires',
      description: 'Augmentez votre chiffre d\'affaires par la vente de produits et services ou par la présentation de votre entreprise et les demandes de devis.',
      icon: CurrencyEuroIcon,
    },
  ]

  const tiers = [
    {
      name: 'Création de site vitrine',
      href: '#',
      description: 'Le site vitrine vous offre la possibilité de développer et d\'informer votre clientèle en ligne.',
      faqs: [
        {
          question: "Un site vitrine moderne",
          answer:
            <ul className="list-disc pl-10">
              <li>Site vitrine adapté aux mobiles, tablettes...</li>
              <li>Style et charte graphique moderne et adaptée à votre image</li>
            </ul>,
        },
        {
          question: "Des fonctionnalités modulables",
          answer:
            <ul className="list-disc pl-10">
              <li>Formulaire de contact / demande de devis</li>
              <li>Galerie d'images / portfolio</li>
              <li>Blog</li>
              <li>Newsletter (lettre d'information)</li>
              <li>Etc...</li>
            </ul>,
        },
        {
          question: "Un accompagnement avant et après-vente",
          answer:
            <p>Nous vous accompagnons du cahier des charges à la réalisation du site jusqu'à la mise en ligne.
              <ul className="list-disc pl-10">
                <li>Formation lors de la mise en ligne</li>
                <li>Support en ligne (Facebook, tchat en ligne sur le site)</li>
                <li>Conseils pour définir les meilleures solutions techniques en tenant compte des facteurs (temps, budget, utilité).</li>
              </ul></p>,
        },
        {
          question: "Un site vitrine conforme au RGPD",
          answer:
            <ul className="list-disc pl-10">
              <li>Sytème de gestion et acceptation des cookies</li>
              <li>Création de pages "Mention Légales", "Politique de Confidentialité"</li>
              <li>Site sécurisé par un certificat de sécurité SSL</li>
            </ul>,
        },
      ]
    },
    {
      name: 'Création de site e-commerce',
      href: '#',
      description: 'Sur un site e-commerce, vous pouvez vendre vos produits et services en ligne.',
      faqs: [
        {
          question: "Un site e-commerce moderne",
          answer:
            <ul className="list-disc pl-10">
              <li>Site e-commerce adapté aux mobiles, tablettes...</li>
              <li>Style et charte graphique moderne et adaptée à votre image</li>
              <li>Tunnel de paiement moderne et efficace</li>
            </ul>,
        },
        {
          question: "Des fonctionnalités modulables",
          answer:
            <ul className="list-disc pl-10">
              <li>Formulaire de contact</li>
              <li>Galerie d'images</li>
              <li>Blog</li>
              <li>Newsletter (lettre d'information)</li>
              <li>Click & Collect</li>
              <li>Etc...</li>
            </ul>,
        },
        {
          question: "Un accompagnement avant et après-vente",
          answer:
            <p>Nous vous accompagnons du cahier des charges à la réalisation du site jusqu'à la mise en ligne.
              <ul className="list-disc pl-10">
                <li>Formation lors de la mise en ligne</li>
                <li>Support en ligne (Facebook, tchat en ligne sur le site)</li>
                <li>Conseils pour définir les meilleures solutions techniques en tenant compte des facteurs (temps, budget, utilité).</li>
              </ul></p>,
        },
        {
          question: "Une boutique en ligne professionnelle",
          answer:
            <ul className="list-disc pl-10">
              <li>Multitude de modes de paiement</li>
              <li>Multitude de modes de livraison</li>
              <li>Paiements sécurisés </li>
              <li>Système de fidélité</li>
              <li>Etc...</li>
            </ul>,
        },
        {
          question: "Un site e-commerce conforme au RGPD",
          answer:
            <ul className="list-disc pl-10">
              <li>Sytème de gestion et acceptation des cookies</li>
              <li>Création de pages "Mention Légales", "Politique de Confidentialité"</li>
              <li>Système de consentement de récolte des données</li>
              <li>Site sécurisé par un certificat de sécurité SSL</li>
            </ul>,
        },
      ],
    },
  ]

  const faqs = [
    {
      question: "Combien coûte un site Internet ?",
      answer:
        <div>
          <p>Le coût d'un site Internet est variable en fonction de la taille de votre site, de la nature de votre activité et de la complexité de votre site.</p>
          <p className="mt-2">Chaque site Internet réalisé par Koomet est créé sur mesure : maquettes fonctionnelles, maquettes graphiques, arborescence...</p>
          <p className="mt-2">Pour un <span className="font-bold">site internet vitrine</span> : entre 500€ et 1500€ (selon les fonctionnalités) - sans frais mensuels supplémentaires</p>
          <p>Pour un <span className="font-bold">site internet e-commerce (boutique en ligne)</span> : entre 1000€ et 3000€ (selon les fonctionnalités) - sans frais mensuels supplémentaires</p>
          <p className="mt-2">Demandez-nous un devis pour obtenir une réponse plus précise sous 24h00.</p>
          <p className="mt-2 text-violet"><a target="_blank" href="https://simulateur.koomet.fr">Simulez le tarif de votre futur site internet !</a></p>
        </div>
        ,
    },
    {
      question: "Existe-t-il des coûts cachés ?",
      answer:
        "L'agence web Koomet vous prépare un devis précis et nous nous engageons à traiter votre devis au prix indiqué sur celui-ci et sans aucuns frais cachés. Vous n'aurez donc pas de mauvaises surprises !",
    },
    {
      question: "Est-ce-que l'hébergement du site Internet est pris en charge ?",
      answer:
        "En effet, les sites Internet de nos clients sont hébergés par une société Française : O2Switch",
    },
    {
      question: "Quel est le délai de création d'un site Internet ?",
      answer:
        "En moyenne, la durée de création d'un site vitrine est de 2 à 3 semaines, celle d'un site e-commerce est de 3 à 4 semaines.",
    },
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


  return (
    <Layout>
      <Seo 
      
      title="Agence de création de site web - Création site internet - Koomet" 
      desc="Besoin d'un site internet ? Confiez votre projet à notre agence web spécialisée dans la création de site internet vitrine, site e-commerce, refonte de site web."
      breadcrumb
      bread1name="Accueil"
      bread1url="/"
      bread2name="Création de site Internet"
      bread2url="/creation-site-internet/"
      />
      <div className="container mx-auto px-6 lg:px-0">
        <div className="relative">

          <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
            <div className="absolute inset-y-0 right-1/2 w-full rounded-r-3xl lg:right-72" />
            <svg
              className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
              width={404}
              height={392}
              fill="none"
              viewBox="0 0 404 392"
            >
              <defs>
                <pattern
                  id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-indigo-100 dark:text-violet/20" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
            </svg>
          </div>
        </div>
        <div className="relative">
          <div className="inset-x-0 bottom-0 h-1/2 bg-gris" />
          <div data-sal="fade" data-sal-duration="800" data-sal-easing="ease" className="max-w-8xl mx-auto lg:px-20">
            <div className="relative shadow-xl rounded-xl sm:overflow-hidden shadow-indigo-100 dark:shadow-md">
              <div className="absolute inset-0">
                <StaticImage
                  src="../assets/Conception.webp"
                  quality={100}
                  alt="Création de site internet vitrine et site e-commerce"
                  className="h-full w-full rounded-xl object-cover"
                />

                <div className="absolute inset-0 rounded-xl bg-gradient-to-t bg-violetBright mix-blend-multiply" />
              </div>
              <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
                <h1 data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease-in-out-cubic" className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">Création de site web</h1>
                <h2 data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease-in-out-cubic" className="mt-6 text-xl text-white max-w-3xl">
                  Confiez votre projet à notre agence web spécialisée dans la création et la refonte de site Internet : site vitrine, site e-commerce.... 
                </h2>

              </div>
            </div>
          </div>
          <div className="max-w-7xl mx-auto pt-16 pb-8 px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto text-center">
              <p className="text-sm font-semibold text-violet uppercase tracking-wide">Site Vitrine • Site E-Commerce</p>
              <h2 className="text-4xl font-extrabold text-gray-900 dark:text-gray-200">Pourquoi créer un site Internet ?</h2>
              <p className="mt-4 text-lg text-gray-500 dark:text-gray-300">
                Un site Internet est un outil de communication qu'il ne faut pas négliger, voici quelques raisons pour vous convaincre de créer votre site Internet.
              </p>
            </div>
            <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
              {features.map((feature) => (
                <div key={feature.name} className="relative text-center p-5 dark:bg-d-secondary dark:outline-violet dark:outline-2 dark:outline rounded-lg">
                  <div className="grid h-full place-items-center">
                    <dt>
                      <feature.icon className="inline-block h-12 w-12 text-green-500" aria-hidden="true" />
                      <p className="mt-4 text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">{feature.name}</p>
                    </dt>
                    <dd className="mt-2 text-base dark:text-gray-300">{feature.description}</dd>
                  </div>
                </div>
              ))}
            </dl>
          </div>
          <div className="relative max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8 shadow-md rounded-xxl">
            <div className="absolute inset-0 w-full h-full transform translate-x-2 translate-y-2 bg-w-primary/20 rounded-xxl group-hover:translate-y-0 group-hover:translate-x-0 transition-all ease-out duration-200"></div>
            <div className="absolute inset-0 w-full h-full border-2 border-violet rounded-xxl"></div>
            <div className="lg:flex items-center justify-between p-2">
              <div className="lg:w-1/2 w-full">
                <p className="text-base leading-4 text-violet">Développez votre présence en ligne</p>
                <h3 role="heading" className="md:text-5xl text-3xl font-bold leading-10 mt-3 text-gray-800 dark:text-gray-200 drop-shadow-lg">Créer votre site web vitrine ou e-commerce</h3>
                <p role="contentinfo" className="text-base leading-5 mt-5 text-gray-800 dark:text-gray-300">La présence en ligne et la communication sur Internet est incontournable pour toutes les entreprises.</p>
                <p role="contentinfo" className="text-base leading-5 mt-5 text-gray-800 dark:text-gray-300">Un site Internet est un outil de communication indispensable, il est disponible 24h/27, 7j/7, ouvert les week-ends et les jours fériés.</p>
                <p role="contentinfo" className="text-base leading-5 mt-5 text-gray-800 dark:text-gray-300">Nous vous aidons à concevoir cet outil essentiel pour la réussite de votre entreprise en prenant en considération vos objectifs et vos contraintes.</p>
              </div>
              <div className="xl:w-1/2 lg:w-7/12 relative w-full lg:mt-0 mt-12 md:pl-10" role="list">
                <div role="listitem" className="bg-white dark:bg-d-secondary cursor-pointer shadow rounded-lg p-8 relative z-30">
                  <div className="md:flex items-center justify-between">
                    <h4 className="text-2xl font-semibold leading-6 text-gray-800 dark:text-gray-200">Création de site web vitrine</h4>
                  </div>
                  <p className="md:w-100 text-base leading-6 mt-4 text-gray-600 dark:text-gray-300">Présentez les produits et services de votre entreprise.</p>
                  <a
                  target="_blank"
                    href="https://simulateur.koomet.fr"
                    className="mt-5 relative flex items-center justify-center w-full px-5 py-5 text-lg font-medium text-white rounded-xl group"
                  >
                    <span className="w-full h-full absolute inset-0 transform translate-y-1.5 translate-x-1.5 group-hover:translate-y-0 group-hover:translate-x-0 transition-all ease-out duration-200 rounded-lg bg-w-primary"></span>
                    <span className="absolute inset-0 w-full h-full border-2 border-violet rounded-lg"></span>
                    <span className="relative">Simuler le tarif</span>
                  </a>
                </div>
                <div role="listitem" className="bg-white dark:bg-d-secondary cursor-pointer shadow rounded-lg p-8 relative z-30 mt-7">
                  <div className="md:flex items-center justify-between">
                    <h4 className="text-2xl font-semibold leading-6 text-gray-800 dark:text-gray-200">Création de site e-commerce</h4>
                  </div>
                  <p className="md:w-100 text-base leading-6 mt-4 text-gray-600 dark:text-gray-300">Vendez vos produits et services en ligne.</p>
                  <a
                    target="_blank"
                    href="https://simulateur.koomet.fr"
                    className="mt-5 relative flex items-center justify-center w-full px-5 py-5 text-lg font-medium text-white rounded-xl group"
                  >
                    <span className="w-full h-full absolute inset-0 transform translate-y-1.5 translate-x-1.5 group-hover:translate-y-0 group-hover:translate-x-0 transition-all ease-out duration-200 rounded-lg bg-w-primary"></span>
                    <span className="absolute inset-0 w-full h-full border-2 border-violet rounded-lg"></span>
                    <span className="relative">Simuler le tarif</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-7xl mx-auto text-center px-4 pt-16 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto lg:max-w-none">
              <p className="text-sm font-semibold text-violet uppercase tracking-wide">Quelques détails sur notre prestation</p>
              <p className="text-3xl font-extrabold text-gray-900 dark:text-gray-200 sm:text-4xl lg:text-4xl">
                Création de site Internet
              </p>
            </div>
          </div>
          <div className="mt-4 pb-12 sm:mt-12 sm:pb-8 lg:mt-8 lg:pb-6">
            <div className="relative">
              <div className="absolute inset-0 h-3/4" />
              <div className="relative z-10 max-w-7xl mx-auto px-0 lg:px-8">
                <div className="mx-auto space-y-4 lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
                  {tiers.map((tier) => (
                    <div key={tier.name} className="flex flex-col rounded-xl shadow-lg overflow-hidden">
                      <div className="px-6 py-8 bg-white dark:bg-d-secondary sm:p-10 sm:pb-6">
                        <div>
                          <p
                            className="inline-flex py-1 text-2xl font-bold tracking-wide uppercase text-violet"
                          >
                            {tier.name}
                          </p>
                        </div>
                        <p className="mt-5 text-lg text-gray-500 dark:text-gray-300">{tier.description}</p>
                      </div>
                      <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 dark:bg-w-primary/40 space-y-6 sm:p-10 sm:pt-6">
                        <dl>
                          {tier.faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-6">
                              {({ open }) => (
                                <>
                                  <dt className="text-lg">
                                    <Disclosure.Button className={classNames(open ? 'bg-indigo-100 border-indigo-100 dark:text-violet' : 'dark:text-gray-300', 'text-left w-full flex justify-between items-start text-violet border-2 border-violet/50 p-3 rounded-md transition-all duration-200 ease-in')}>
                                      <span className={classNames(open ? 'dark:text-violet' : '', 'font-medium text-violet dark:text-gray-300')}>{faq.question}</span>
                                      <span className="ml-6 h-7 flex items-center">
                                        <ChevronDownIcon
                                          className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform transition duration-100 ease-in-out')}
                                          aria-hidden="true"
                                        />
                                      </span>
                                    </Disclosure.Button>
                                  </dt>
                                  <Transition
                                    show={open}
                                    enter="transition-opacity duration-75"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="transition-opacity duration-150"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Disclosure.Panel static as="dd" className="mt-4 pr-12">
                                      <p className="text-base text-gray-500 dark:text-gray-300">{faq.answer}</p>
                                    </Disclosure.Panel>
                                  </Transition>
                                </>
                              )}
                            </Disclosure>
                          ))}
                        </dl>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="mx-auto py-4 sm:py-16">
            <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200 dark:divide-violetBright/50">
              <p className="text-center text-3xl font-extrabold text-gray-900 dark:text-gray-200 sm:text-4xl">Questions fréquentes</p>
              <dl className="mt-6">
                {faqs.map((faq) => (
                  <Disclosure as="div" key={faq.question} className="pt-6">
                    {({ open }) => (
                      <>
                        <dt className="text-lg">
                          <Disclosure.Button className="text-left w-full flex justify-between items-start text-violet bg-indigo-100 p-5 rounded-md">
                            <span className="font-medium text-violet">{faq.question}</span>
                            <span className="ml-6 h-7 flex items-center">
                              <ChevronDownIcon
                                className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform transition duration-100 ease-in-out')}
                                aria-hidden="true"
                              />
                            </span>
                          </Disclosure.Button>
                        </dt>
                        <Transition
                          show={open}
                          enter="transition-opacity duration-75"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="transition-opacity duration-150"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Disclosure.Panel static as="dd" className="mt-4 pr-12">
                            <p className="text-base text-gray-500 dark:text-gray-300">{faq.answer}</p>
                          </Disclosure.Panel>
                        </Transition>
                      </>
                    )}
                  </Disclosure>
                ))}
              </dl>
            </div>
          </div>
          <div className="relative max-w-7xl mx-auto py-12">
            <p className="font-bold text-3xl text-gray-900 dark:text-gray-200">Plus de services pour votre site web</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
              <AniLink cover bg="#6377E9" direction="up" duration={0.6} to="/referencement-naturel-site-internet/" data-sal="zoom-in"
                data-sal-duration="500" data-sal-easing="ease-in-out-quad" className="hover:scale-105 flex flex-col p-10 rounded-xl transition shadow-blue-400/5 dark:from-blue-500/5 dark:to-blue-400/30 bg-gradient-to-br from-blue-50  to-white shadow-xl hover:bg-opacity-90 active:bg-opacity-100 focus:outline-none focus:ring-4 focus:ring-blue-200 focus:ring-opacity-40">
                <p className="flex-none inline-flex items-center space-x-2 text-2xl font-semibold text-blue-400 mb-2">
                  <PresentationChartLineIcon className="inline-block w-5 h-5 opacity-50" />
                  <span>Référencement naturel (SEO)</span>
                </p>
                <p className="flex-grow text-gray-600 dark:text-white opacity-90 leading-relaxed text-sm">
                Améliorez votre positionnement et optimisez la visibilité de votre site Internet sur les moteurs de recherche (Google, Bing...) grâce à notre expertise SEO.
                </p>
              </AniLink>
              <AniLink cover bg="#6377E9" direction="up" duration={0.6} to="/maintenance-site-internet/" data-sal="zoom-in"
                data-sal-duration="500" data-sal-easing="ease-in-out-quad" className="hover:scale-105 flex flex-col p-10 rounded-xl transition shadow-red-400/5  dark:from-red-500/5 dark:to-red-400/30 bg-gradient-to-br from-red-50 to-white shadow-xl hover:bg-opacity-90 active:bg-opacity-100 focus:outline-none focus:ring-4 focus:ring-red-200 focus:ring-opacity-40">
                <p className="flex-none inline-flex items-center space-x-2 text-2xl font-semibold text-red-400 mb-2">
                  <CogIcon className="inline-block w-5 h-5 opacity-50" />
                  <span>Maintenance de site Internet</span>
                </p>
                <p className="flex-grow text-gray-600 dark:text-white opacity-90 leading-relaxed text-sm">
                Assurez le bon fonctionnement de votre site Internet, confiez sa maintenance à notre agence web (ajout de contenu, sauvegardes, mise à jour de contenu, etc.).
                </p>
              </AniLink>
            </div>
          </div>
        </div>
      </div>
      <div className="relative bg-gradient-to-r from-indigo-400 via-violet to-indigo-400">
        <div className="max-w-3xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
          <p className="text-3xl font-extrabold text-white sm:text-4xl">
            <span className="block">Vous avez un projet de création de site Internet ?</span>
          </p>
          <p className="mt-4 text-2xl font-medium leading-6 text-indigo-100">
            N'hésitez pas à nous en parler.
          </p>
          <AniLink
            cover bg="#6377E9" direction="up" duration={0.6} to="/contact/"
            className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50 sm:w-auto"
          >
            Parlons-en ! 🤗
          </AniLink>
        </div>
      </div>
    </Layout>
  )
}

export default ConceptionSiteInternet
